import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LockIcon from 'components/icons/lock.svg?react';
import { theme } from 'theme';

export const Blocked = ({ label }: { label: string }) => {
  return (
    <Stack direction={'row'} alignItems={'center'} gap={0.5} color={theme.palette.error.main}>
      <LockIcon />
      <Typography variant="body3">{label}</Typography>
    </Stack>
  );
};
