import { HomePageRedirect } from 'page-components/HomePageRedirect';
import RedirectToAgentProfilePage from 'page-components/profiles/AgentProfilePage/RedirectToAgentProfilePage';
import { RouteObject } from 'react-router-dom';

export default [
  {
    index: true,
    element: <HomePageRedirect />,
  },
  {
    path: 'dashboard',
    lazy: () => import('page-components/dashboard'),
  },
  {
    path: 'statistics',
    lazy: () => import('page-components/statistics'),
  },
  {
    path: 'leads',
    lazy: () => import('page-components/LeadListPage'),
  },
  {
    path: 'contact/:contactId',
    lazy: () => import('page-components/contact/LayoutContact'),
    children: [
      {
        index: true,
        lazy: () => import('page-components/contact/ContactOverviewPage'),
      },
      {
        path: 'contactdetails',
        lazy: () => import('page-components/contact/ContactDetailsPage'),
      },
      {
        path: 'activities',
        lazy: () => import('page-components/contact/ContactActivitiesPage/ContactActivitiesPage'),
      },
      {
        path: 'lead/:leadId/matching',
        lazy: () => import('page-components/contact/lead/MatchingPage/MatchingPage'),
      },
      {
        path: 'lead/:leadId',
        lazy: () => import('page-components/contact/lead/LeadPage/LeadPage'),
      },
      {
        path: 'proposedlistings',
        lazy: () => import('page-components/contact/ContactProposedListingsPage'),
      },
      {
        path: 'documents',
        lazy: () => import('page-components/contact/ContactDocumentsPage'),
      },
    ],
  },
  {
    path: 'contacts',
    lazy: () => import('page-components/contacts/ContactListPage'),
  },
  {
    path: 'mails',
    lazy: () => import('page-components/mails/MailsLayout'),
    children: [
      {
        path: 'inbox',
        lazy: () => import('page-components/mails/mail/MailPage'),
      },
      {
        path: 'sent',
        lazy: () => import('page-components/mails/mail/MailPage'),
      },
      {
        path: 'sentbulk',
        lazy: () => import('page-components/mails/mail/BulkMailPage'),
      },
      {
        path: ':threadIndex',
        lazy: () => import('page-components/mails/ThreadPage'),
      },
      {
        path: 'setting/integrations',
        lazy: () => import('page-components/mails/settings/integrations/MyIntegrationsPage'),
      },
      {
        path: 'setting/templates',
        lazy: () => import('page-components/mails/settings/templates/MyTemplatesPage'),
      },
      {
        path: 'setting/signatures',
        lazy: () => import('page-components/mails/settings/signatures/MySignaturesPage'),
      },
    ],
  },
  {
    path: 'calls',
    lazy: () => import('page-components/calls/CallPage'),
  },
  {
    path: 'calls/:callId',
    lazy: () => import('page-components/calls/CallDetailsPage'),
  },
  {
    path: 'user/shop',
    lazy: () => import('page-components/profiles/ShopProfilePage/ShopProfileLayout'),
    children: [
      {
        path: 'details',
        lazy: () => import('page-components/profiles/ShopProfilePage/ShopDetailsPage'),
      },
      {
        path: 'settings',
        lazy: () => import('page-components/profiles/ShopProfilePage/ShopSettingsPage'),
      },
      {
        path: 'profile',
        lazy: () => import('page-components/profiles/ShopProfilePage/ShopProfilePage'),
      },
      {
        path: 'export',
        lazy: () => import('page-components/profiles/ShopProfilePage/ShopExportPage'),
      },
      {
        index: true,
        lazy: () => import('page-components/profiles/ShopProfilePage/ShopMembersPage'),
      },
    ],
  },
  {
    path: 'user/my-profile',
    element: <RedirectToAgentProfilePage />,
  },
  {
    path: 'profiles/lipa',
    lazy: () => import('page-components/profiles/LiPaProfilePage'),
    children: [
      {
        path: 'details',
        lazy: () => import('page-components/profiles/LiPaProfilePage/LiPaProfileDetailsPage/LiPaProfileDetailsPage'),
      },
      {
        index: true,
        lazy: () => import('page-components/profiles/LiPaProfilePage/LipaProfilePage'),
      },
    ],
  },
  {
    path: 'user/:agentId',
    lazy: () => import('page-components/profiles/AgentProfilePage/AgentProfileLayout'),
    children: [
      {
        path: 'details',
        lazy: () => import('page-components/profiles/AgentProfilePage/AgentProfileDetailsPage'),
      },
      {
        index: true,
        lazy: () => import('page-components/profiles/AgentProfilePage/AgentProfilePage'),
      },
    ],
  },
  {
    path: 'network',
    lazy: () => import('page-components/network/Network'),
  },
  {
    path: 'admin',
    lazy: () => import('page-components/admin/AdminPage'),
  },
  {
    path: 'referrals',
    lazy: () => import('page-components/referrals/Referrals'),
  },
  {
    path: 'activities',
    lazy: () => import('page-components/activities/ActivityPage'),
  },
] satisfies RouteObject[];
