import { latLngToLatLngTuple } from 'components/map/LeafletMap/LeafletMapUtils';
import { LeafletMarker } from 'components/map/LeafletMap/LeafletMarker';
import Leaflet from 'leaflet';
import React, { useEffect, useMemo } from 'react';
import { useMap } from 'react-leaflet';
import { LatLng, Marker } from 'util/places/mapMarkers';
import usePrevious from 'util/usePrevious';

type LeafletMarkersWithPopupProps = { markers?: Marker[]; editable?: boolean; handleDrag?: (latlng: LatLng) => void };

export const LeafletMarkersWithPopups: React.FC<LeafletMarkersWithPopupProps> = ({
  markers = [],
  editable,
  handleDrag,
}) => {
  const map = useMap();

  const boundsWithAllMarkers: Leaflet.LatLngBounds | undefined = useMemo(() => {
    if (markers.length === 0) {
      return undefined;
    }

    const latLngMarkers = markers.map((m) => latLngToLatLngTuple(m.position));
    const markersBounds = new Leaflet.LatLngBounds(latLngMarkers);

    return latLngMarkers.length === 1
      ? markersBounds.pad(1) // padding for single marker
      : markersBounds;
  }, [markers]);
  const prevBoundsWithAllMarkers = usePrevious(boundsWithAllMarkers);

  useEffect(() => {
    if (!boundsWithAllMarkers) {
      return;
    }

    // only zoom to marker when it's new
    if (!prevBoundsWithAllMarkers) {
      map.fitBounds(boundsWithAllMarkers, { maxZoom: 18 });
    }

    if (prevBoundsWithAllMarkers && !boundsWithAllMarkers.equals(prevBoundsWithAllMarkers, 0.0001)) {
      map.fitBounds(boundsWithAllMarkers, { maxZoom: 18 });
    }
  }, [boundsWithAllMarkers, map, prevBoundsWithAllMarkers]);

  return (
    <>
      {markers.map((marker) => (
        <LeafletMarker
          key={marker.id}
          marker={marker}
          draggable={marker.id === 'ADDRESS' && editable}
          handleDrag={handleDrag}
        />
      ))}
    </>
  );
};
