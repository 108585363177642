import {
  ActivityTypeEnum,
  AppointmentTypeEnum,
  BaseActivityCategoryEnum,
  DbBaseActivityBoolExp,
  DbContactBoolExp,
  DbContactEmailAddressBoolExp,
  DbReferralBoolExp,
  TaskTypeEnum,
} from 'api/graphql/generated/graphql';
import { endOfHour } from 'date-fns';
import { Maybe } from 'graphql/jsutils/Maybe';
import { isSet } from 'util/filters';
import { removeDiacriticsAndSymbols } from 'util/stringUtils';

const FIND_QUOTE_GROUPS_REGEX = /".+"/g;
const POTENTIAL_PHONE_NUMBER_REGEX = /^[0-9+]+$/;
const POTENTIAL_CONTACT_ID_REGEX = /^CNT/i;
const POTENTIAL_CONTACT_UTAG_REGEX = /^C-/i;
const POTENTIAL_EMAIL_REGEX = /@/;

export const DEFAULT_LAST_INTERACTION_FILTER_CATEGORIES: BaseActivityCategoryEnum[] = [
  'TASK',
  'APPOINTMENT',
  'EMAIL',
  'DOCUMENT',
  'CALL',
  'SHARED',
];

export function buildFilters<R>() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function <D extends Record<string, any>>(
    data: D,
    filterMap: { [k in keyof D]: (value: D[k]) => R },
    additionalFilters: R[] = [],
  ): { _and?: R[] } {
    const filters = Object.entries(filterMap).reduce((agg, [name, func]) => {
      if (data[name] && (!Array.isArray(data[name]) || data[name].length)) {
        const filter = func(data[name]);
        // Avoid adding duplicate filters
        if (!agg.some((existing) => JSON.stringify(existing) === JSON.stringify(filter))) {
          agg.push(filter);
        }
      }
      return agg;
    }, [] as R[]);

    return filters.length ? { _and: [...filters, ...additionalFilters] } : {};
  };
}

export function agentSearchFilter(text: string | undefined) {
  if (!text) {
    return {};
  }

  const normalizedText = removeDiacriticsAndSymbols(text);

  return {
    _or: [
      {
        _and: normalizedText.split(/\s/).map((token) => ({
          _or: [{ normalizedFirstName: { _like: `%${token}%` } }, { normalizedLastName: { _like: `%${token}%` } }],
        })),
      },
      { normalizedUserName: { _like: `%${normalizedText}%` } },
    ],
  };
}

export function teamSearchFilter(text: string | undefined) {
  if (!text) {
    return {};
  }

  const normalizedText = removeDiacriticsAndSymbols(text);
  return {
    normalizedName: { _like: `%${normalizedText}%` },
  };
}

export function contactTagSearchFilter(text: string | undefined) {
  if (!text) {
    return {};
  }

  const normalizedText = removeDiacriticsAndSymbols(text);
  return {
    name: { _ilike: `%${normalizedText}%` },
  };
}

export function contactIdFilter(text: Maybe<string>) {
  if (text?.match(POTENTIAL_CONTACT_ID_REGEX)) {
    return { contactId: { _eq: text?.toUpperCase() } };
  }
}

export function contactSearchNameFilter(
  text: Maybe<string>,
  extraFilterSettings?: {
    includePhoneNumber?: boolean;
    includeId?: boolean;
    includeUtag?: boolean;
    includeEmail?: boolean;
  },
): DbContactBoolExp {
  if (!text) {
    return {};
  }

  const {
    includePhoneNumber = true,
    includeId = true,
    includeUtag = true,
    includeEmail = true,
  } = extraFilterSettings || {};

  const couldBePhoneNumber = text.match(POTENTIAL_PHONE_NUMBER_REGEX);
  const couldBeId = text.match(POTENTIAL_CONTACT_ID_REGEX);
  const couldBeUtag = text.match(POTENTIAL_CONTACT_UTAG_REGEX);
  const couldBeEmail = text.match(POTENTIAL_EMAIL_REGEX);
  const couldBeName = !(couldBeEmail || couldBePhoneNumber);

  const filters = [
    couldBeName ? { _and: getFuzzyContactNameFilter(text) } : undefined,
    includePhoneNumber && couldBePhoneNumber
      ? {
          phoneNumbers: {
            _or: [
              {
                internationalNumber: { _like: `%${text}%` },
              },
              {
                number: { _like: `%${text}%` },
              },
            ],
          },
        }
      : undefined,
    includeId && couldBeId ? { id: { _eq: text?.toUpperCase() } } : undefined,
    includeUtag && couldBeUtag ? { go3Utag: { _eq: text?.toUpperCase() } } : undefined,
    includeEmail && couldBeEmail ? { emailAddresses: contactEmailAddressFilter(text) } : undefined,
  ].filter(isSet);

  return {
    _or: filters,
  };
}

export function contactSearchExactMatchNameFilter(text: Maybe<string>): DbContactBoolExp | undefined {
  const useExactMatch = text?.match(FIND_QUOTE_GROUPS_REGEX)?.length;

  if (!useExactMatch) {
    return undefined;
  }

  const exactMatchFilter = getExactMatchNameFilter(text);

  const fuzzyMatchString = text.replaceAll(FIND_QUOTE_GROUPS_REGEX, '');
  const fuzzyMatchFilter = getFuzzyContactNameFilter(fuzzyMatchString);

  return {
    _and: [...fuzzyMatchFilter, ...exactMatchFilter],
  };
}

//Text inside of "" should filter for an exact match
function getExactMatchNameFilter(text: string): DbContactBoolExp[] {
  const exactMatchArray = text.match(FIND_QUOTE_GROUPS_REGEX) ?? [];

  return exactMatchArray.map((match) => {
    const searchString = match.replaceAll('"', '');
    const normalizedSearchString = removeDiacriticsAndSymbols(match);
    return {
      _or: [
        { firstName: { _eq: searchString } },
        { lastName: { _eq: searchString } },
        { fullName: { _eq: searchString } },
        { normalizedLowerFirstName: { _eq: normalizedSearchString } },
        { normalizedLowerLastName: { _eq: normalizedSearchString } },
      ],
    };
  });
}

export function contactSearchExactMatchLegalEntityNameFilter(text: Maybe<string>) {
  const useExactMatch = text?.match(FIND_QUOTE_GROUPS_REGEX)?.length;

  if (!useExactMatch) {
    return undefined;
  }

  const exactMatchFilter = getExactMatchLegalEntityNameFilter(text);

  const fuzzyMatchString = text.replaceAll(FIND_QUOTE_GROUPS_REGEX, '');
  const fuzzyMatchFilter = getFuzzyLegalEntityNameFilter(fuzzyMatchString);

  return {
    _and: [...fuzzyMatchFilter, ...exactMatchFilter],
  };
}

function getExactMatchLegalEntityNameFilter(text: string): DbContactBoolExp[] {
  const exactMatchArray = text.match(FIND_QUOTE_GROUPS_REGEX) ?? [];

  return exactMatchArray.map((match) => {
    const searchString = match.replaceAll('"', '');
    return {
      legalEntity: { name: { _eq: searchString } },
    };
  });
}

function getFuzzyLegalEntityNameFilter(text: string): DbContactBoolExp[] {
  const fuzzyMatchNormalized = removeDiacriticsAndSymbols(text);
  const fuzzyMatchArray = fuzzyMatchNormalized.split(/\s+/).filter(Boolean);

  return fuzzyMatchArray.map((token) => ({
    legalEntity: { name: { _ilike: `%${token}%` } },
  }));
}

export function contactSearchLegalEntityNameFilter(text: Maybe<string>): DbContactBoolExp {
  if (!text) {
    return {};
  }

  return {
    _or: getFuzzyLegalEntityNameFilter(text),
  };
}

function getFuzzyContactNameFilter(text: string): DbContactBoolExp[] {
  const fuzzyMatchNormalized = removeDiacriticsAndSymbols(text);
  const fuzzyMatchArray = fuzzyMatchNormalized.split(/\s+/).filter(Boolean);

  return fuzzyMatchArray.map((token) => ({
    _or: [
      { normalizedLowerFirstName: { _ilike: `%${token}%` } },
      { normalizedLowerLastName: { _ilike: `%${token}%` } },
    ],
  }));
}

export function contactEmailAddressFilter(text: string | undefined): DbContactEmailAddressBoolExp {
  if (!text) {
    return {};
  }

  const normalizedText = removeDiacriticsAndSymbols(text);

  return {
    normalizedAddress: { _like: `%${normalizedText}%` },
  };
}

export function referralSearchFilter(text: Maybe<string>): DbReferralBoolExp {
  if (!text) {
    return {};
  }
  const fuzzyMatchNormalized = removeDiacriticsAndSymbols(text);

  return {
    snapshotLead: {
      _and: fuzzyMatchNormalized.split(/\s+/).map((token) => ({
        _or: [
          { normalizedLowerContactFirstName: { _ilike: `%${token}%` } },
          { normalizedLowerContactLastName: { _ilike: `%${token}%` } },
        ],
      })),
    },
  };
}

export function lastInteractionWhereFilter({
  isLastInteractionSettingsEnabled,
  leadExtendingActivities,
  leadExtendingTaskTypes,
  leadExtendingAppointmentTypes,
}: {
  isLastInteractionSettingsEnabled?: boolean;
  leadExtendingActivities?: ActivityTypeEnum[];
  leadExtendingTaskTypes?: TaskTypeEnum[];
  leadExtendingAppointmentTypes?: AppointmentTypeEnum[];
}): DbBaseActivityBoolExp {
  return isLastInteractionSettingsEnabled
    ? {
        _or: [
          {
            type: { _in: leadExtendingActivities },
          },
          {
            category: { _eq: 'TASK' },
            taskActivity: {
              task: {
                type: { _in: leadExtendingTaskTypes },
              },
            },
          },
          {
            category: { _eq: 'APPOINTMENT' },
            appointmentActivity: {
              appointment: {
                type: { _in: leadExtendingAppointmentTypes },
              },
            },
          },
        ],
        orderDate: {
          _lte: endOfHour(new Date()),
        },
      }
    : {
        category: { _in: DEFAULT_LAST_INTERACTION_FILTER_CATEGORIES },
      };
}
