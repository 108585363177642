import { Box, Button, Stack, useMediaQuery } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';
import ArrowLeftIcon from 'components/icons/arrow_2_left.svg?react';
import PlusIcon from 'components/icons/plus_naked.svg?react';
import 'leaflet-draw/dist/leaflet.draw.css';
import { theme } from 'theme';

import { ActionsModal } from 'components/general/ActionsModal/ActionsModal';
import { useState } from 'react';
import { useTranslation } from 'util/i18next';

const EditButtons = ({
  buttons: { back, add, save },
}: {
  buttons: Record<
    'back' | 'add' | 'save',
    {
      disabled: boolean;
      onClick: () => void;
    }
  >;
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  return (
    <Box
      sx={{
        width: isMobile ? '80vw' : '100%',
        position: 'absolute',
        pointerEvents: 'none',
        bottom: theme.spacing(isMobile ? 5 : 2),
        zIndex: zIndex.drawer - 1,
      }}
    >
      <Stack
        direction="row"
        className="leaflet-bar"
        sx={{
          backgroundColor: theme.palette.backgroundExtension.white,
          justifyContent: 'space-between',
          pointerEvents: 'all',
          p: 1,
          ml: isMobile ? 2 : 'auto',
          mr: 'auto',
          width: isMobile ? '100%' : 'min(600px, 90vw)',
        }}
      >
        <Stack>
          <Button
            sx={{ px: 2 }}
            color="secondary"
            startIcon={<ArrowLeftIcon />}
            onClick={() => {
              if (back.disabled) {
                setIsOpen(true);
              } else {
                back.onClick();
              }
            }}
          >
            {!isMobile && t('map.control.back')}
          </Button>
        </Stack>
        <Stack direction="row" gap={2}>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<PlusIcon />}
            disabled={add.disabled}
            onClick={add.onClick}
          >
            {isMobile ? t('common:map.control.addPolygon.short') : t('common:map.control.addPolygon')}
          </Button>
          <Button disabled={save.disabled} variant="contained" onClick={save.onClick}>
            {isMobile ? t('common:map.control.saveAreas.short') : t('common:map.control.saveAreas')}
          </Button>
        </Stack>
      </Stack>
      <ActionsModal
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        title={t('map.control.discardChanges.title')}
        actions={[
          {
            message: t('confirm'),
            variant: 'outlined',
            color: 'secondary',
            handleClick: () => {
              setIsOpen(false);
              back.onClick();
            },
          },
          {
            message: t('cancel'),
            variant: 'contained',
            color: 'primary',
            handleClick: () => {
              setIsOpen(false);
            },
          },
        ]}
      />
    </Box>
  );
};

export default EditButtons;
