import { CircularProgress, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { PublicContact, usePublicContacts } from 'api/graphql/hooks/useContact';
import { EVChip, EVChipProps } from 'components/general/Chips/EVChip/EVChip';
import { EntityTooltip } from 'components/general/Chips/EntityTooltip/EntityTooltip';
import { EntityTooltipHeader } from 'components/general/Chips/EntityTooltip/EntityTooltipHeader';
import { EmailTooltipButton } from 'components/general/Chips/TooltipButton/EmailTooltipButton';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { LinkButton } from 'components/general/LinkButton';
import React, { PropsWithChildren } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { EMPTY_DATA_STRING, renderEmptyableString } from 'util/emptyDataUtils';
import { useTranslation } from 'util/i18next';
import { useDestination } from 'util/navigation/useDestination';
import { fullName } from 'util/stringUtils';
import { sxTruncate } from 'util/styleUtils';

type ContactTooltipChipProps = {
  chipProps?: EVChipProps;
  contactId?: string | null;
  handleDelete?: () => void;
  currentEmail?: string;
  linkTarget?: string;
  sx?: SxProps;
  asLink?: boolean;
};

export function ContactTooltip({ contactId, sx, children }: PropsWithChildren<{ contactId: string; sx?: SxProps }>) {
  const { publicContact, isLoading } = usePublicContacts(
    {
      where: { id: { _eq: contactId } },
    },
    { staleTime: 300_000 },
  );

  return (
    <InnerContactTooltip publicContact={publicContact} isLoading={isLoading} sx={sx}>
      {children}
    </InnerContactTooltip>
  );
}

export const ContactTooltipChip: React.FC<ContactTooltipChipProps> = ({
  chipProps,
  contactId,
  handleDelete,
  currentEmail,
  linkTarget,
  asLink = true,
  sx,
}) => {
  const { ref, inView } = useInView({ triggerOnce: true });

  const { publicContact, isLoading } = usePublicContacts(
    { where: { id: { _eq: contactId } } },
    { enabled: !!contactId && inView, staleTime: 300_000 },
  );

  const label = fullName({ firstName: publicContact?.firstName, lastName: publicContact?.lastName });

  const { toContactDetails } = useDestination();

  const linkTo =
    publicContact?.contact?.id && !publicContact.contact.blocked
      ? toContactDetails({ contactId: publicContact?.contact?.id })
      : undefined;
  const withoutTooltip = !publicContact?.contact || publicContact.contact.blocked;

  function getContent() {
    if (!contactId) {
      return EMPTY_DATA_STRING;
    }

    if (withoutTooltip) {
      return (
        <EVChip
          avatar={
            isLoading ? (
              <CircularProgress variant="indeterminate" size={24} />
            ) : (
              <EVAvatar size="l" person={publicContact} />
            )
          }
          {...chipProps}
          label={label}
          interactive={false}
        />
      );
    }

    return (
      <InnerContactTooltip
        linkTo={linkTo}
        publicContact={publicContact}
        isLoading={isLoading}
        sx={sx}
        currentEmail={currentEmail}
      >
        <EVChip
          avatar={
            isLoading ? (
              <CircularProgress variant="indeterminate" size={24} />
            ) : (
              <EVAvatar size="l" person={publicContact} />
            )
          }
          {...chipProps}
          onDelete={handleDelete}
          label={label}
        />
      </InnerContactTooltip>
    );
  }

  if (contactId && asLink && linkTo) {
    return (
      <Box ref={ref}>
        <Link to={linkTo} target={linkTarget}>
          {getContent()}
        </Link>
      </Box>
    );
  }

  return <Box ref={ref}>{getContent()}</Box>;
};

function InnerContactTooltip({
  publicContact,
  children,
  linkTo,
}: PropsWithChildren<{
  publicContact: PublicContact | undefined;
  isLoading: boolean;
  sx?: SxProps;
  currentEmail?: string;
  linkTo?: string;
}>) {
  const label = fullName({ firstName: publicContact?.firstName, lastName: publicContact?.lastName });
  const { t } = useTranslation(['contact']);

  const { emailAddresses, phoneNumbers } = publicContact?.contact || {};

  return (
    <EntityTooltip
      header={
        <EntityTooltipHeader
          label={linkTo ? <LinkButton to={linkTo}>{label}</LinkButton> : label}
          avatar={<EVAvatar size="l" person={publicContact} />}
          subLabel={
            <>
              {emailAddresses?.map((email) => (
                <Box key={email.address} sx={sxTruncate}>
                  {email.address}
                </Box>
              ))}
              {phoneNumbers?.map((phone) => (
                <Box key={phone.number}>{phone.number}</Box>
              ))}
            </>
          }
        />
      }
      content={t('contact:contactId') + ': ' + renderEmptyableString(publicContact?.contact?.go3Utag)}
      buttons={!!publicContact?.contact?.id && <EmailTooltipButton to={[{ contactId: publicContact?.contact?.id }]} />}
    >
      {children}
    </EntityTooltip>
  );
}
