import MuiChip, { ChipProps as MUIChipProps } from '@mui/material/Chip';
import { forwardRef } from 'react';
import { theme } from 'theme';
import { sxTruncate } from 'util/styleUtils';

export interface EVChipProps extends MUIChipProps {
  selected?: boolean;
  interactive?: boolean;
}

export const EVChip = forwardRef<HTMLDivElement, EVChipProps>(function Chip(props, ref) {
  const { sx, selected, interactive = true, onDelete, disabled, ...passThroughProps } = props;
  const fullTransparent = theme.palette.transparent.full;

  const style = { ...sx, ...sxTruncate };

  return (
    <MuiChip
      ref={ref}
      disabled={disabled}
      sx={{
        background: interactive ? (selected ? theme.palette.shade.grey1 : theme.palette.shade.grey4) : fullTransparent,
        color: selected ? theme.palette.textExtension.white : theme.palette.text.primary,
        textTransform: 'none',
        cursor: !interactive || selected ? undefined : 'pointer',
        maxWidth: 300,
        '&&:hover': !interactive
          ? { background: fullTransparent, opacity: 1 }
          : selected
          ? {
              background: theme.palette.shade.grey1,
            }
          : {
              color: theme.palette.text.primary,
              background: theme.palette.shade.grey4,
            },
        '& .MuiChip-icon': {
          color: 'inherit',
        },
        '& .MuiChip-label': {
          textOverflow: 'ellipsis',
        },
        '& .MuiChip-deleteIcon': {
          color: selected ? 'white' : 'inherit',
        },
        ...style,
      }}
      onDelete={onDelete}
      {...passThroughProps}
    />
  );
});
