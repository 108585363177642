import type { Handler } from 'leaflet';
import { PropsWithChildren, useEffect, useState } from 'react';

const loadLeafletPromise = loadLeaflet();

let gestureHandling: typeof Handler;

async function loadLeaflet() {
  await import('leaflet');

  // Make sure leaflet extensions are load after leaflet
  // Just ordering the imports correctly is not enough because leaflet is shared
  [{ GestureHandling: gestureHandling }] = await Promise.all([
    import('leaflet-gesture-handling'),
    import('leaflet-draw'),
    import('leaflet-fullscreen'),
  ]);
}

export function LeafletLoader({ children }: PropsWithChildren) {
  const [leafletLoaded, setLeafletLoaded] = useState(false);

  useEffect(() => {
    loadLeafletPromise.then(() => setLeafletLoaded(true));
  }, []);

  if (!leafletLoaded) {
    return null;
  }

  return <>{children}</>;
}

export function getGestureHandling() {
  return gestureHandling;
}
