import { useLocale } from '@ev/eva-container-api';
import { CurrencyEnum, Scalars } from 'api/graphql/generated/graphql';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useCallback, useMemo } from 'react';
import { EMPTY_DATA_STRING, replaceUndefinedOption } from 'util/emptyDataUtils';

const METER_SQUARE = '\u33A1';

export type NumberStyle = 'decimal' | 'currency' | 'percent' | 'unit';

export interface INumberOptions {
  unit?: string;
  style?: NumberStyle;
  currency?: CurrencyEnum | Scalars['PropertyEngineCurrency'] | null;
  emptyDataString?: string;
  locale?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

export interface INumericRange {
  from?: number;
  to?: number;
  options?: INumberOptions;
}

export interface IFormattedNumber {
  number?: number;
  options?: INumberOptions;
}

function getNumericFormatOptions(
  { unit, style, currency, minimumFractionDigits, maximumFractionDigits }: INumberOptions,
  defaultCurrency: Maybe<CurrencyEnum | Scalars['PropertyEngineCurrency']>,
): Intl.NumberFormatOptions {
  return {
    ...(unit ? { style: 'unit', unit } : { style }),
    ...(style === 'currency'
      ? {
          currency:
            replaceUndefinedOption(currency || undefined) ||
            replaceUndefinedOption(defaultCurrency || undefined) ||
            'EUR',
        }
      : {}),
    minimumFractionDigits: minimumFractionDigits ?? 0,
    maximumFractionDigits: maximumFractionDigits ?? 2,
  };
}

export function useFormatNumber() {
  const locale = useLocale();
  const { shopCurrency } = useActiveShop();

  const formatNumber = useCallback(
    (number: Maybe<number>, options: INumberOptions = {}): string => {
      if (number === undefined || number === null) {
        return options?.emptyDataString ?? EMPTY_DATA_STRING;
      }

      const numericFormat = new Intl.NumberFormat(locale, getNumericFormatOptions(options, shopCurrency));
      if (options.style === 'currency') {
        return numericFormat
          .format(number)
          .replace(/^(\D+)/, '$1 ')
          .replace(/\s+/, ' ');
      }
      return numericFormat.format(number);
    },
    [locale, shopCurrency],
  );

  const formatRange = (from: Maybe<number>, to: Maybe<number>, options: INumberOptions = {}): string => {
    const formattedFrom = formatNumber(from, options);
    const formattedTo = formatNumber(to, options);

    if (from && to) {
      return `${formattedFrom} - ${formattedTo}`;
    }
    if (from) {
      return `> ${formattedFrom}`;
    }
    if (to) {
      return `< ${formattedTo}`;
    }
    return options?.emptyDataString ?? EMPTY_DATA_STRING;
  };

  const formatPrice = (
    number: Maybe<number>,
    options: INumberOptions & { currency: CurrencyEnum | Scalars['PropertyEngineCurrency'] | undefined | null },
  ) => formatNumber(number, { ...options, style: 'currency' });

  const formatPriceRange = (
    from: Maybe<number>,
    to: Maybe<number>,
    options: INumberOptions & { currency: CurrencyEnum | Scalars['PropertyEngineCurrency'] | undefined | null },
  ) => formatRange(from, to, { ...options, style: 'currency' });

  const formatArea = (number: Maybe<number>, options: INumberOptions = {}) =>
    formatNumber(number, { ...options, unit: 'meter' })?.replace(/(\d\s)m/g, `$1${METER_SQUARE}`);

  const formatAreaRange = (from: Maybe<number>, to: Maybe<number>, options: INumberOptions = {}) =>
    formatRange(from, to, { ...options, unit: 'meter' })?.replace(/(\d\s)m/g, `$1${METER_SQUARE}`);

  const formatPercent = (number: Maybe<number>, options: INumberOptions = {}, isPercentage = true) => {
    //Format number will multiply percent values by 100, not needed if this is already a percentage
    if (number && isPercentage) {
      number = number / 100;
    }
    return formatNumber(number, { ...options, style: 'percent' });
  };

  return {
    formatRange,
    formatNumber,
    formatPrice,
    formatPriceRange,
    formatArea,
    formatAreaRange,
    formatPercent,
  };
}

export function useCurrencySymbol(currency: CurrencyEnum | Scalars['PropertyEngineCurrency']) {
  const locale = useLocale();
  return useMemo(() => {
    return (0)
      .toLocaleString(locale, {
        style: 'currency',
        currency: replaceUndefinedOption(currency) || 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim();
  }, [currency, locale]);
}
