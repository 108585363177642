import { useSnackBars } from '@ev/eva-container-api';
import { InputBase } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Call } from '@twilio/voice-sdk';
import { BaseActivity } from 'api/graphql/generated/graphql';
import { ActiveCallPage } from 'components/calls/ActiveCallPopover/ActiveCallPopover';
import { CallSubpageHeader } from 'components/calls/ActiveCallPopover/CallSubpageHeader';
import { CallFrame } from 'components/calls/CallFrame';
import { CallOutcome } from 'components/state/Twilio';
import { callOutcome } from 'const/enumTranslations';
import { useErrorSnackBar } from 'util/useErrorSnackBar';

import { useUpdateCallActivity } from 'api/rest/hooks/useActivity';
import { EVChip } from 'components/general/Chips/EVChip/EVChip';
import { useState } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

export interface CallOutcomePageProps {
  callStatus?: Call.State;
  onClose: () => void;
  onPageChange: (page: ActiveCallPage) => void;
  callDuration: number;
  headerTitle: string;
  headerColor: string;
  activity: BaseActivity;
}

export function CallOutcomePage({
  onClose,
  onPageChange,
  callStatus,
  callDuration,
  headerTitle,
  headerColor,
  activity,
}: CallOutcomePageProps) {
  const { t } = useTranslation(['communication']);

  const { callActivity } = activity;
  const [callOutcome, setCallOutcome] = useState<CallOutcome | undefined>(callActivity?.call?.outcome as CallOutcome);
  const [callNote, setCallNote] = useState(callActivity?.call?.note || '');
  const { openSnackBar } = useSnackBars();
  const { openErrorSnackBar } = useErrorSnackBar();

  const { updateCallActivity, isLoading } = useUpdateCallActivity();

  const handleUpdateActivity = () => {
    const { callActivity } = activity;
    const callSid = callActivity?.call?.callSid;
    if (!callSid) {
      return;
    }
    updateCallActivity(
      {
        requestBody: {
          callOutcome: callOutcome,
          callNote: callNote || undefined,
          leadId: activity.leadId || undefined,
          callSid,
        },
      },
      {
        onSuccess: () => {
          openSnackBar(t('communication:call.activeCall.updateOutcomeSuccess'), 'success');
          onPageChange('overview');
        },
        onError: (error) => openErrorSnackBar(t('communication:call.activeCall.updateOutcomeError'), error),
      },
    );
  };

  return (
    <CallFrame
      onClose={onClose}
      title={headerTitle}
      headerColor={headerColor}
      variant="light"
      actions={
        <Button color="primary" variant="contained" onClick={handleUpdateActivity} disabled={isLoading}>
          {t('communication:call.activeCall.saveOutcomeButton')}
        </Button>
      }
    >
      <CallSubpageHeader onPageChange={onPageChange} callStatus={callStatus} callDuration={callDuration} />
      <Typography variant="h2" sx={{ marginTop: 4, marginBottom: 2, textAlign: 'center' }}>
        {t('communication:call.activeCall.callOutcomeTitle')}
      </Typography>
      <CallOutcomePills selectedOutcome={callOutcome} setSelectedOutcome={setCallOutcome} />
      <CallNote note={callNote} setNote={setCallNote} />
    </CallFrame>
  );
}

const OUTCOMES = [
  'TALKED',
  'LEFT_MESSAGE',
  'BUSY',
  'NO_ANSWER',
  'FAILED',
  'WRONG_NUMBER',
] as const satisfies readonly CallOutcome[];

export function CallOutcomePills({
  selectedOutcome,
  setSelectedOutcome,
}: {
  selectedOutcome: CallOutcome | undefined;
  setSelectedOutcome: (outcome: CallOutcome | undefined) => void;
}) {
  const { t } = useTranslation(['enums']);
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }} gap={2}>
      {OUTCOMES.map((outcome) => (
        <EVChip
          key={outcome}
          label={t(callOutcome[outcome])}
          selected={selectedOutcome === outcome}
          onClick={() => setSelectedOutcome(selectedOutcome === outcome ? undefined : outcome)}
        />
      ))}
    </Box>
  );
}

export function CallNote({ note, setNote }: { note: string; setNote: (note: string) => void }) {
  const { t } = useTranslation(['communication']);

  return (
    <Box
      sx={{
        flex: 'auto',
        overflow: 'auto',
        marginY: 4,
        backgroundColor: theme.palette.shade.grey5,
        borderBottom: `solid 1px ${theme.palette.shade.grey3}`,
      }}
    >
      <InputBase
        fullWidth
        placeholder={t('communication:call.activeCall.callNotePlaceholder')}
        value={note}
        onChange={(e) => {
          setNote(e.target.value);
        }}
        sx={{
          height: '100%',
          alignItems: 'flex-start',
          fontSize: theme.typography.body3,
          display: 'flex',
          textarea: {
            padding: '5px',
          },
        }}
        multiline
      />
    </Box>
  );
}
