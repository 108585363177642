import { FormHelperText, SxProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { PropertyEngineListingStatus } from 'api/graphql/generated/graphql';
import { useListingSearch } from 'api/graphql/hooks/useListingSearch';
import { PropertyTooltipChip } from 'components/general/Chips/TooltipChip/PropertyTooltipChip';
import { PropertyItem } from 'components/property/PropertySearch/PropertyItem';
import { useActiveShopId } from 'components/state/ActiveShopProvider';
import { ReactNode, SyntheticEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ListingWithLegacyFields } from 'util/go3';
import { useTranslation } from 'util/i18next';
import { useSearchText } from 'util/useDebounce';
import { useShowError } from 'util/useShowError';

const getOptionsToSearch = (option: ListingWithLegacyFields) => {
  let matchString = '';
  if (option?.address?.city) {
    matchString += option.address.city;
  }
  if (option?.address?.streetName) {
    matchString += option.address.streetName;
  }
  if (option?.utag) {
    matchString += option.utag;
  }
  return matchString;
};

export const PropertySearchField = ({
  disabled,
  sx,
  required,
  label,
  maxProperties,
  hideArrow = false,
  onlyActiveProperties = false,
  isEmail = false,
}: {
  disabled?: boolean;
  sx?: SxProps;
  required?: boolean;
  label?: string;
  maxProperties?: number;
  hideArrow?: boolean;
  onlyActiveProperties?: boolean;
  isEmail?: boolean;
}) => {
  const { activeShopId } = useActiveShopId();
  const { t } = useTranslation(['enums', 'communication', 'errors']);

  const { searchText, setSearchText } = useSearchText();
  const { watch, control } = useFormContext();
  const formValueProperties = watch('properties');

  const activeOnlyOptions = ['ACTIVE', 'OFFER_SHOW'] as PropertyEngineListingStatus[];

  const allEmailOptions = [
    'ACTIVE',
    'PENDING',
    'OFFER_SHOW',
    'OFFER_NO_SHOW',
    'STREET_INDEX',
  ] as PropertyEngineListingStatus[];

  const emailOptions = onlyActiveProperties ? activeOnlyOptions : allEmailOptions;
  const listingStatusOptions = isEmail ? emailOptions : undefined;

  const { listings, isLoading, error } = useListingSearch(
    {
      shopId: activeShopId ?? '',
      query: searchText,
      listingStatus: listingStatusOptions,
    },
    { enabled: !!searchText },
  );

  useShowError(t('communication:emailForm.propertySearch.error'), !!error);

  const onSearchStringChanged = (_: SyntheticEvent<Element, Event>, value: string) => {
    setSearchText(value);
  };

  const isDisabled = !!maxProperties && formValueProperties?.length >= maxProperties;
  const maxPropertiesLabelSuffix = maxProperties
    ? ` ${t('communication:emailForm.properties.max.value', { value: maxProperties })}`
    : '';

  return (
    <Box sx={sx}>
      <Controller
        control={control}
        name="properties"
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Autocomplete
            open={!!searchText.length}
            noOptionsText={t('communication:propertySearch.noMatches')}
            loading={isLoading}
            loadingText={t('communication:emailForm.properties.loading')}
            disableClearable
            filterOptions={(options) => options}
            PaperComponent={CustomPaper}
            multiple
            limitTags={3}
            value={value}
            disabled={disabled}
            options={listings || []}
            getOptionLabel={(option) => {
              return typeof option === 'string' ? option : getOptionsToSearch(option);
            }}
            onChange={(_, items) => {
              onChange(items);
            }}
            isOptionEqualToValue={(option: string | ListingWithLegacyFields, value: ListingWithLegacyFields) => {
              if (!option || !value) {
                return false;
              }

              return typeof option === 'string' ? option === value.utag : option.utag === value.utag;
            }}
            onInputChange={(event, newInputValue) => onSearchStringChanged(event, newInputValue)}
            id="properties"
            renderOption={(props, property: ListingWithLegacyFields) => (
              <MenuItem {...props}>
                <PropertyItem property={property} />
              </MenuItem>
            )}
            freeSolo={false}
            getOptionDisabled={() => isDisabled}
            filterSelectedOptions
            renderTags={(value: ListingWithLegacyFields[], getTagProps) =>
              value.map((option, index: number) => (
                <PropertyTooltipChip
                  key={option.utag}
                  propertyId={option.utag || ''}
                  chipProps={{ ...getTagProps({ index }), interactive: true }}
                />
              ))
            }
            renderInput={(params) => (
              <>
                <TextField
                  variant="outlined"
                  {...params}
                  label={label ?? `${t('communication:emailForm.properties.label')}${maxPropertiesLabelSuffix}`}
                  required={required}
                  error={!!error}
                  InputProps={{
                    sx: {
                      gap: 1,
                    },
                    ...params.InputProps,
                    endAdornment: hideArrow ? null : params.InputProps.endAdornment,
                  }}
                />
                <Stack direction="row">
                  <FormHelperText>{error?.message}</FormHelperText>
                </Stack>
              </>
            )}
          />
        )}
      />
    </Box>
  );
};

function CustomPaper(props: { className?: string; children?: ReactNode }) {
  return (
    <Paper
      sx={{
        '& .MuiAutocomplete-listbox': {
          '& .MuiAutocomplete-option': {
            display: 'block',
          },
        },
      }}
      {...props}
    />
  );
}
