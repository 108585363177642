import { getKeycloakToken } from '@ev/eva-container-api';
import { createFetchError } from 'api/FetchError';
import { env } from 'env';
import createClient, { FetchOptions, FetchResponse } from 'openapi-fetch';
import type { MediaType } from 'openapi-typescript-helpers';
import { paths } from './generated-rest';

export type PostResponseData<PATH extends keyof paths> = paths[PATH] extends { post: unknown }
  ? FetchResponse<paths[PATH]['post'], unknown, MediaType>['data']
  : never;
export type PostRequestBody<PATH extends keyof paths> = paths[PATH] extends { post: unknown }
  ? FetchOptions<paths[PATH]['post']>['body']
  : never;
export type DeleteRequestBody<PATH extends keyof paths> = paths[PATH] extends { delete: unknown }
  ? FetchOptions<paths[PATH]['delete']>['body']
  : never;
export type GetRequestBody<PATH extends keyof paths> = paths[PATH] extends { get: unknown }
  ? FetchOptions<paths[PATH]['get']>['body']
  : never;
export type PutRequestBody<PATH extends keyof paths> = paths[PATH] extends { put: unknown }
  ? FetchOptions<paths[PATH]['put']>['body']
  : never;
export type GetRequestQuery<PATH extends keyof paths> = paths[PATH] extends { get: unknown }
  ? FetchOptions<paths[PATH]['get']> extends { params: { query: unknown } }
    ? FetchOptions<paths[PATH]['get']>['params']['query']
    : never
  : never;
export type GetResponseData<PATH extends keyof paths> = paths[PATH] extends { get: unknown }
  ? FetchResponse<paths[PATH]['get'], unknown, MediaType>['data']
  : never;

export const fetchClient = createClient<paths>({
  baseUrl: env.VITE_BACKEND_API_URL!.replace('/api', ''),
  fetch: (...args) => fetch(...args), // make it interceptable for msw
});

fetchClient.use({
  async onRequest({ request }) {
    const accessToken = await getKeycloakToken();
    request.headers.set('Authorization', accessToken ? `Bearer ${accessToken}` : '');

    // @ts-expect-error Create a clone so that we read the request body on error
    request.requestClone = request.clone();
    return request;
  },
  async onResponse({ response, request }) {
    if (!response.ok) {
      // @ts-expect-error Use request clone
      throw await createFetchError(`${response.status} - Request error`, response, request.requestClone);
    }
  },
});

if (['local', 'local-dev', 'feature', 'dev', 'stage'].includes(env.VITE_LEADHUB_ENV)) {
  // expose API for E2E tests
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).fetchClient = fetchClient;
}

export function toMultiPartFormData<T extends Record<string, unknown>>(parts: T & { files?: File[] }) {
  const formData = new FormData();
  for (const file of parts['files'] || []) {
    formData.append('files', file);
  }

  for (const [key, value] of Object.entries(parts)) {
    if (key !== 'files') {
      formData.append(key, new Blob([JSON.stringify(value)], { type: 'application/json' }));
    }
  }
  // The type is a lie here. This is to match the generate types that do not reflect the encoding.
  return formData as unknown as T & { files?: string[] };
}
