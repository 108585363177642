import { useActiveShop } from 'components/state/ActiveShopProvider';
import { useIsFeatureEnabled } from 'eva-frame/EvaProviders/FeatureFlagProvider';
import { useCallback } from 'react';
import { usePermissions } from 'util/usePermissions';

export type Page =
  | 'Dashboard'
  | 'Statistics'
  | 'LeadList'
  | 'LeadDetails'
  | 'ActivityList'
  | 'ContactList'
  | 'Properties'
  | 'Mails'
  | 'Calls'
  | 'CallDetails'
  | 'Admin'
  | 'Network'
  | 'MyProfile'
  | 'Integrations'
  | 'MyShopTeam'
  | 'Templates'
  | 'Signatures'
  | 'ShopProfile'
  | 'ShopSettings'
  | 'ShopExport'
  | 'MyLicensePartner'
  | 'KYC'
  | 'Referrals';

export function useEnabledPages() {
  const { activeShop, isFullyOnboardedShop, isLevelOneShop, activeLicensePartner } = useActiveShop();
  const kycIntegrationEnabled = useIsFeatureEnabled('KYC_INTEGRATION');
  const shopExportPageEnabled = isFullyOnboardedShop;
  const shopHasAtLeastLevel1 = isFullyOnboardedShop || isLevelOneShop;
  const {
    canSeeAdminUI,
    canCall,
    canSeeLeadHubMainPages,
    canUseLevelOneFeatures,
    canSeeLiPaPage,
    canEditShopProfile,
    canSeeKYCPages,
    canSeeShopExport,
  } = usePermissions();

  const showLeadHubMainPages = canSeeLeadHubMainPages(activeShop);
  const showLevelOneFeatures = isFullyOnboardedShop || (isLevelOneShop && canUseLevelOneFeatures(activeShop));

  return useCallback(
    (page: Page) => {
      switch (page) {
        case 'LeadList':
        case 'LeadDetails':
        case 'ActivityList':
        case 'ContactList':
        case 'Integrations':
        case 'ShopSettings':
        case 'Mails':
        case 'Signatures':
          return showLeadHubMainPages && showLevelOneFeatures;
        case 'Statistics':
        case 'Dashboard':
        case 'Referrals':
        case 'Properties':
          return showLeadHubMainPages && isFullyOnboardedShop;
        case 'Templates':
          return (
            showLeadHubMainPages &&
            (isFullyOnboardedShop || (!isFullyOnboardedShop && canUseLevelOneFeatures(activeShop)))
          );
        case 'ShopProfile':
          return canEditShopProfile(activeShop);
        case 'Calls':
        case 'CallDetails':
          return canCall(activeShop) && shopHasAtLeastLevel1;
        case 'Admin':
          return canSeeAdminUI();
        case 'Network':
        case 'MyProfile':
        case 'MyShopTeam':
          return true;
        case 'KYC':
          return kycIntegrationEnabled && canSeeKYCPages(activeShop);
        case 'MyLicensePartner':
          return (
            activeLicensePartner &&
            !activeLicensePartner.isCompanyOwnedOperation &&
            canSeeLiPaPage(activeLicensePartner)
          );
        case 'ShopExport':
          return shopExportPageEnabled && canSeeShopExport(activeShop);
      }
    },
    [
      activeLicensePartner,
      activeShop,
      canCall,
      canEditShopProfile,
      canSeeAdminUI,
      canSeeKYCPages,
      canSeeLiPaPage,
      canUseLevelOneFeatures,
      isFullyOnboardedShop,
      kycIntegrationEnabled,
      shopHasAtLeastLevel1,
      showLeadHubMainPages,
      showLevelOneFeatures,
      canSeeShopExport,
      shopExportPageEnabled,
    ],
  );
}
