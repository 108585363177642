import Autocomplete, { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useAgentsInShopGroup } from 'api/graphql/hooks/useAgents';
import { useContactRelationships } from 'api/graphql/hooks/useContact';
import { ContactItem } from 'components/contact/LeadAndContactPicker/LeadAndContactOptionItems';
import {
  LeadAndContactSearchOption,
  getContactId,
} from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import { TypeLabel } from 'components/emails/SendEmailPopover/RecipientPicker';
import { EVChip } from 'components/general/Chips/EVChip/EVChip';
import { AgentTooltipChip } from 'components/general/Chips/TooltipChip/AgentTooltipChip';
import { ContactTooltipChip } from 'components/general/Chips/TooltipChip/ContactTooltipChip';
import { PersonMenuItem } from 'components/general/person/PersonItem';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { contactRelationshipTypeTranslation } from 'const/enumTranslations';
import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { theme } from 'theme';
import { getPreferredEmail } from 'util/contactUtils';
import { getId } from 'util/email';
import { agentSearchFilter, contactSearchNameFilter } from 'util/hasura/filters';
import { useTranslation } from 'util/i18next';
import { EmailFormData } from 'util/schemas/sendEmailSchema';
import { CcRecipient } from 'util/schemas/useCcAndBccSchema';
import { useSearchText } from 'util/useDebounce';

function getEmail(recipient: CcRecipient) {
  switch (recipient.type) {
    case 'AGENT':
    case 'EMAIL':
      return recipient.email;
    case 'CONTACT':
    case 'RELATED_CONTACT':
      return getPreferredEmail(recipient);
  }
}

export function CcRecipientPicker({
  value,
  onChange,
  onBlur,
  label,
  disabled,
  errorMessage,
}: {
  value: CcRecipient[];
  onChange: (value: CcRecipient[]) => void;
  onBlur?: () => void;
  label: React.ReactNode;
  disabled?: boolean;
  errorMessage?: string;
}) {
  const { shopIdsInActiveGroup } = useActiveShop();

  const { searchText, rawSearchText, setSearchText } = useSearchText();

  const { agentsInShopGroup = [] } = useAgentsInShopGroup({
    shopIds: shopIdsInActiveGroup,
    where: agentSearchFilter(searchText),
  });

  const { watch } = useFormContext<EmailFormData>();
  const recipientsTo = watch('to');

  const selectedContactOrLead = recipientsTo.find(
    (recipient) => recipient.type === 'CONTACT' || recipient.type === 'LEAD',
  );
  const contactId = selectedContactOrLead
    ? getContactId(selectedContactOrLead as LeadAndContactSearchOption)
    : undefined;

  const { contactRelationships = [] } = useContactRelationships(
    {
      contactId,
      where: {
        contactStatus: { _neq: 'FLAGGED' },
        blocked: { _neq: true },
        deleted: { _neq: true },
        ...contactSearchNameFilter(searchText),
      },
    },
    { enabled: !!contactId },
  );

  const isOpen = !!rawSearchText.length;

  const recipients: CcRecipient[] = [
    ...contactRelationships.map((contactRelationship) => ({
      type: 'RELATED_CONTACT' as const,
      relationship: contactRelationship.type,
      ...contactRelationship.relatedContact,
    })),
    ...agentsInShopGroup.map((agent) => ({
      type: 'AGENT' as const,
      ...agent,
    })),
  ];

  const lastRenderedOptionType = useRef<CcRecipient['type']>();

  return (
    <>
      <Autocomplete
        open={isOpen}
        disabled={disabled}
        value={value}
        disableClearable
        onBlur={onBlur}
        multiple
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          switch (option.type) {
            case 'AGENT':
            case 'CONTACT':
            case 'RELATED_CONTACT':
              return [option.firstName, option.lastName, getEmail(option)].filter(Boolean).join();
            case 'EMAIL':
              return option.email;
          }
        }}
        onChange={(_, items) => {
          onChange(items.map((item) => (typeof item === 'string' ? { type: 'EMAIL', email: item } : item)));
        }}
        isOptionEqualToValue={(option: string | CcRecipient, value: CcRecipient) =>
          typeof option === 'string' ? option === getEmail(value) : getId(option) === getId(value)
        }
        onInputChange={(_, newInputValue) => setSearchText(newInputValue)}
        options={recipients}
        getOptionDisabled={(option: CcRecipient) => !getEmail(option)}
        renderOption={(props, option, state) => {
          if (state.index === 0) {
            lastRenderedOptionType.current = undefined;
          }
          const isFirstOfType = lastRenderedOptionType.current !== option.type;
          lastRenderedOptionType.current = option.type;

          return <RenderOption option={option} isFirstOfType={isFirstOfType} props={props} />;
        }}
        filterOptions={(options) => options} // Needed to disable the internal filtering
        freeSolo={true}
        filterSelectedOptions
        renderTags={(value, getTagProps) =>
          value.map((option, index: number) => <RenderTag option={option} getTagProps={getTagProps} index={index} />)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={!!errorMessage}
            helperText={errorMessage}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
    </>
  );
}

function RenderOption({
  option,
  isFirstOfType,
  props,
}: {
  option: CcRecipient;
  isFirstOfType: boolean;
  props: React.HTMLAttributes<HTMLLIElement>;
}) {
  const { t } = useTranslation(['communication', 'enums']);
  switch (option.type) {
    case 'AGENT':
      return (
        <React.Fragment key={option.id}>
          {isFirstOfType && <TypeLabel>{t('communication:sendBulkEmailDialog.ccType.agent')}</TypeLabel>}
          <PersonMenuItem person={option} {...props} />
        </React.Fragment>
      );
    case 'RELATED_CONTACT':
      return (
        <React.Fragment key={option.id}>
          {isFirstOfType && <TypeLabel>{t('communication:sendBulkEmailDialog.ccType.relationship')}</TypeLabel>}
          <ContactItem key={option.id} {...props} option={{ ...option, email: getPreferredEmail(option) || '' }}>
            <Typography variant="body4" sx={{ color: theme.palette.text.secondary }}>
              {t(contactRelationshipTypeTranslation[option.relationship])}
            </Typography>
          </ContactItem>
        </React.Fragment>
      );
    default:
      return null;
  }
}

function RenderTag({
  option,
  getTagProps,
  index,
}: {
  option: CcRecipient;
  getTagProps: AutocompleteRenderGetTagProps;
  index: number;
}) {
  switch (option.type) {
    case 'AGENT':
      return (
        <AgentTooltipChip key={index} handleDelete={() => getTagProps({ index }).onDelete(index)} agentId={option.id} />
      );
    case 'RELATED_CONTACT':
    case 'CONTACT':
      return (
        <ContactTooltipChip
          key={index}
          handleDelete={() => getTagProps({ index }).onDelete(index)}
          contactId={option.id}
          asLink={false}
        />
      );
    case 'EMAIL':
      return <EVChip label={option.email} {...getTagProps({ index })} key={index} selected={false} />;
    default:
      return null;
  }
}
