import Button from '@mui/material/Button';
import { useCheckContactDuplicates } from 'api/rest/hooks/useContacts';
import { DuplicateContactInfo } from 'api/rest/models/ContactDuplicates';
import { DuplicateText } from 'components/contact/ContactDetailsForm/CheckForDuplicates/DuplicateText';
import { FormSection, FormSectionHeader } from 'components/general/Form/FormSection';
import { FormStack } from 'components/general/Form/FormStack';
import UserDoubleIcon from 'components/icons/user_double.svg?react';
import { useActiveShopId } from 'components/state/ActiveShopProvider';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { theme } from 'theme';
import { useIsContactQualified } from 'util/contactUtils';
import { isTruthy } from 'util/filters';
import { useTranslation } from 'util/i18next';
import { ContactDetailsFormData } from 'util/schemas/contactDetailsSchema';
import { useShowError } from 'util/useShowError';

export const CheckForDuplicates = () => {
  const { setValue, control } = useFormContext<ContactDetailsFormData>();
  const [duplicateChecked, setDuplicateChecked] = useState(false);
  const [duplicates, setDuplicates] = useState<DuplicateContactInfo[] | undefined>(undefined);
  const { activeShopId } = useActiveShopId();
  const { isContactQualified } = useIsContactQualified();
  const { checkContactDuplicatesAsync, isLoading, isError } = useCheckContactDuplicates();
  const [isEnabled, setIsEnabled] = useState(false);
  const [change, setChange] = useState<string | undefined>(undefined);
  const { t } = useTranslation(['contact', 'enums']);
  useShowError(t('contact:duplicateCheck.error'), isError);

  const watchedFields = useWatch({
    control,
    name: ['firstName', 'lastName', 'emailAddresses', 'phoneNumbers'],
  });
  const watchedContactId = useWatch({ control, name: '_internals.contact.id' });

  const [firstName, lastName, emailAddresses, phoneNumbers] = watchedFields;

  const checkForDuplicates = async () => {
    const contactDuplicates = await checkContactDuplicatesAsync({
      requestBody: {
        contactId: watchedContactId,
        shopId: activeShopId ?? '',
        firstName: firstName?.trim(),
        lastName: lastName?.trim(),
        emailAddresses: emailAddresses.map((email) => email.email?.trim()).filter(isTruthy),
        phoneNumbers: phoneNumbers.map((phone) => phone.number).filter(isTruthy),
      },
    });
    setDuplicates(contactDuplicates);
    setValue('_internals.contact.contactQualified', !contactDuplicates?.length);
    setDuplicateChecked(true);
  };

  useEffect(() => {
    const newChange = JSON.stringify(watchedFields);
    const isQualified = isContactQualified({
      firstName,
      lastName,
      emailAddresses,
      phoneNumbers,
    });
    if (!change) {
      setValue('_internals.contact.contactQualified', isQualified);
      setIsEnabled(false);
      setDuplicates(undefined);
    } else if (change !== newChange) {
      setValue('_internals.contact.contactQualified', false);
      setIsEnabled(isQualified);
      setDuplicateChecked(false);
    } else {
      setDuplicates(undefined);
    }
    setChange(newChange);
  }, [
    watchedFields,
    change,
    setChange,
    setValue,
    setDuplicates,
    setIsEnabled,
    firstName,
    lastName,
    emailAddresses,
    phoneNumbers,
    isContactQualified,
  ]);

  return (
    <FormSection>
      <FormSectionHeader>{t('contact:drawer.duplicateCheck')}</FormSectionHeader>
      <FormStack
        icon={<UserDoubleIcon />}
        sx={{ fontSize: theme.typography.body3.fontSize, lineHeight: 1.75 }}
        alignItems={'center'}
      >
        <DuplicateText duplicates={duplicates || []} duplicateChecked={duplicateChecked} />
      </FormStack>
      {!duplicateChecked && (
        <FormStack sx={{ mt: -2 }}>
          <Button
            color="secondary"
            variant="contained"
            disabled={!isEnabled || isLoading}
            onClick={() => checkForDuplicates()}
            data-testid="duplicateCheckButton"
          >
            {t('contact:duplicateCheck.checkCTA')}
          </Button>
        </FormStack>
      )}
    </FormSection>
  );
};
