import { Button, ButtonGroup, Stack, TextField } from '@mui/material';
import CheckIcon from 'components/icons/check_naked.svg?react';
import CrossIcon from 'components/icons/cross_naked.svg?react';
import TrashIcon from 'components/icons/trash.svg?react';
import { LeafletPopup } from 'components/map/LeafletMap/LeafletPopup';
import Leaflet from 'leaflet';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

export interface PolygonPopupRef {
  close: () => void;
}

interface PolygonPopupProps {
  name: string;
  existingNames: string[];
  handleNameUpdate: (name: string) => void;
  onDelete: () => void;
}

const PolygonPopup = forwardRef<PolygonPopupRef, PolygonPopupProps>((props, ref) => {
  const popupRef = useRef<Leaflet.Popup>(null);
  const [polygonName, setPolygonName] = useState('');
  useImperativeHandle(ref, () => ({
    close: () => {
      popupRef?.current?.close();
    },
  }));

  useEffect(() => {
    setPolygonName(props.name || '');
  }, [props.name]);

  const isNameDuplicated = props.existingNames.indexOf(polygonName.toLowerCase()) !== -1;

  return (
    <LeafletPopup ref={popupRef} closeOnEscapeKey closeButton={false}>
      <Stack direction="row" margin={-2} sx={{ background: 'white' }}>
        <TextField
          value={polygonName}
          sx={{ width: '162px' }}
          onChange={(e) => {
            e.preventDefault();
            setPolygonName(e.target.value);
          }}
          error={isNameDuplicated}
        />
        <ButtonGroup>
          <Button
            variant="contained"
            sx={{ borderRadius: '0 2px 2px 0', px: 2 }}
            disabled={isNameDuplicated}
            onClick={() => props.handleNameUpdate(polygonName)}
          >
            <CheckIcon />
          </Button>
          <Button
            color="secondary"
            sx={{ px: 2 }}
            onClick={(e) => {
              e.preventDefault();
              props.onDelete();
              popupRef?.current?.close();
            }}
          >
            <TrashIcon />
          </Button>
          <Button
            color="secondary"
            sx={{ px: 2, borderRadius: 0, mr: -0.25 }}
            onClick={() => popupRef.current?.close()}
          >
            <CrossIcon />
          </Button>
        </ButtonGroup>
      </Stack>
    </LeafletPopup>
  );
});

export default PolygonPopup;
