import { ErrorBoundary } from 'components/error/ErrorBoundary';
import { boundingBoxToLatLngBounds } from 'components/map/LeafletMap/LeafletMapUtils';
import { LeafletMarkersWithPopups } from 'components/map/LeafletMap/LeafletMarkersWithPopups';
import { StyledMapContainer } from 'components/map/LeafletMap/StyledMapContainer';
import type { LeafletMouseEvent } from 'leaflet';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import 'leaflet/dist/leaflet.css';
import React, { CSSProperties, useEffect, useMemo } from 'react';
import { MapContainerProps, ZoomControl, useMap, useMapEvents } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { useTranslation } from 'util/i18next';
import { getGestureHandling } from 'util/leaflet-loader';
import { googleMapsLoaderConf } from 'util/places/createMapsApiLoader';
import { LatLng, Marker } from 'util/places/mapMarkers';

export type LeafletOnMapClickProps = { onMapClick: (latLng: LatLng) => void };

export const LeafletOnMapClick: React.FC<LeafletOnMapClickProps> = ({ onMapClick }) => {
  useMapEvents({
    click: (clickEvent: LeafletMouseEvent) => {
      onMapClick(clickEvent.latlng);
    },
  });

  return null;
};

type LeafletMapProps = {
  boundsWithoutMarkers?: {
    southWest: LatLng;
    northEast: LatLng;
  };
  children?: React.ReactNode;
  containerStyle?: CSSProperties;
  markers?: Marker[];
  onMapClick?: (latLng: LatLng) => void;
  editable?: boolean;
  handleDrag?: (latLng: LatLng) => void;
  gestureHandling?: boolean;
};

export const LeafletMap: React.FunctionComponent<LeafletMapProps> = ({
  boundsWithoutMarkers,
  children,
  containerStyle = { height: '200px', width: '100%' },
  markers = [],
  onMapClick,
  handleDrag,
  editable,
  gestureHandling = false,
}) => {
  const mapContainerProps: MapContainerProps = useMemo(
    () => ({
      ...(boundsWithoutMarkers
        ? { bounds: boundingBoxToLatLngBounds(boundsWithoutMarkers) }
        : {
            center: [0, 0],
            zoom: 2,
          }),
      minZoom: 1,
      style: containerStyle,
    }),
    [boundsWithoutMarkers, containerStyle],
  );

  return (
    <ErrorBoundary iconSize="small">
      <StyledMapContainer {...mapContainerProps} zoomControl={false}>
        {children}
        <ReactLeafletGoogleLayer useGoogMapsLoader={true} googleMapsLoaderConf={googleMapsLoaderConf} />
        {onMapClick && <LeafletOnMapClick onMapClick={onMapClick} />}
        <LeafletMarkersWithPopups markers={markers} editable={editable} handleDrag={handleDrag} />
        {gestureHandling && <LeafletGestureHandling />}
        <ZoomControl position="bottomright" />
      </StyledMapContainer>
    </ErrorBoundary>
  );
};

function LeafletGestureHandling() {
  const { t } = useTranslation();
  const map = useMap();

  useEffect(() => {
    map.addHandler('gestureHandling', getGestureHandling());
    // @ts-expect-error typescript does not see additional handler here
    map.gestureHandling.enable();
    // @ts-expect-error typescript does not see additional handler here
    map.options.gestureHandlingOptions = {
      text: {
        // Replace \\u with \u to avoid parsing error
        touch: t('common:map.gesture.touch').replace(/\\u2318/g, '\u2318'),
        scroll: t('common:map.gesture.scroll').replace(/\\u2318/g, '\u2318'),
        scrollMac: t('common:map.gesture.scrollMac').replace(/\\u2318/g, '\u2318'),
      },
      duration: 2000,
    };
  }, [map, t]);

  return null;
}
