import { Button } from '@mui/material';
import EditIcon from 'components/icons/pencil.svg?react';
import PolygonEditPanel from 'components/map/LeafletMap/Editor/PolygonEditPanel';
import PolygonEditToggle from 'components/map/LeafletMap/Editor/PolygonEditToggle';
import PolygonPopup, { PolygonPopupRef } from 'components/map/LeafletMap/Editor/PolygonPopup';
import useFullScreenControls from 'components/map/LeafletMap/Editor/useFullscreenControls';
import type { Feature, FeatureCollection, GeoJsonProperties, Polygon } from 'geojson';
import type { FeatureGroup as FG, GeoJSON, Layer, LeafletKeyboardEvent, LeafletMouseEvent, PathOptions } from 'leaflet';
import Leaflet from 'leaflet';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FeatureGroup, Tooltip, useMap } from 'react-leaflet';
import { useTranslation } from 'util/i18next';

interface LeafletPolygonsProps {
  geojson: FeatureCollection<Polygon, GeoJsonProperties>;
  setGeojson?: (geojson: FeatureCollection<Polygon, GeoJsonProperties>) => void;
}

declare module 'leaflet' {
  interface Layer {
    _leaflet_id: number;
    editing?: {
      enable: () => void;
      disable: () => void;
    };
    feature: Feature;
  }
}

declare module 'geojson' {
  interface Polygon {
    feature: Feature;
  }
}

export default function LeafletPolygons({ geojson, setGeojson }: LeafletPolygonsProps) {
  const [isDirty, setIsDirty] = useState(false);
  const { t } = useTranslation();
  const map = useMap();
  const [activeLayer, setActiveLayer] = useState<Layer | null>(null);
  const { isFullscreen, toggleFullscreen, removeFullscreenButton, addFullscreenButton } = useFullScreenControls();
  const ref = useRef<FG>(null);
  const drawRef = useRef<Leaflet.Draw.Polygon | null>(null);
  const popupRef = useRef<PolygonPopupRef>(null);

  const shapeOptions: PathOptions = {
    weight: 2,
  };

  const [tooltipName, setTooltipName] = useState();

  const resetState = () => {
    drawRef.current?.disable();
    addFullscreenButton();
    toggleFullscreen();
    ref.current?.clearLayers();
    Leaflet.geoJSON(geojson).eachLayer((layer) => {
      ref.current?.addLayer(layer);
    });
  };

  const getPolygonName = useCallback(
    (feature: Feature, leafletId?: number) => {
      if (!leafletId) {
        return undefined;
      }
      return (
        feature?.properties?.name ||
        t('map.customSearchAreas.templateName', {
          index: (ref.current?.getLayers()?.findIndex((layer) => layer._leaflet_id === leafletId) || 0) + 1,
        })
      );
    },
    [t],
  );

  const eventHandlers = useMemo(
    () => ({
      click(e: LeafletMouseEvent) {
        if (!isFullscreen) {
          return;
        }
        const isTargetInEditMode = e.propagatedFrom?.editing?.enabled();
        ref.current?.eachLayer((layer: Layer) => {
          layer?.editing?.disable();
        });
        if (isTargetInEditMode) {
          handlePopupClose();
          setActiveLayer(null);
        } else {
          removeFullscreenButton();
          setActiveLayer(e.propagatedFrom);
          e.propagatedFrom?.editing?.enable?.();
        }
      },
      mouseover(e: LeafletMouseEvent) {
        setTooltipName(getPolygonName(e.propagatedFrom.feature, e.propagatedFrom._leaflet_id));
      },
      keyup(e: LeafletKeyboardEvent) {
        if (e.originalEvent.key === 'Escape') {
          turnOffEditMode();
        }
      },
    }),
    [isFullscreen, removeFullscreenButton, getPolygonName],
  );

  useEffect(() => {
    ref.current?.clearLayers();
    if (ref.current?.getLayers().length === 0 && geojson) {
      Leaflet.geoJSON(geojson).eachLayer((layer) => {
        ref.current?.addLayer(layer);
      });
    }
  }, [geojson]);

  if (!setGeojson) {
    return <FeatureGroup ref={ref} pathOptions={shapeOptions} />;
  }

  const turnOffEditMode = () => {
    setActiveLayer(null);
    ref.current?.eachLayer((layer) => {
      layer.editing?.disable();
    });
  };

  const handlePopupClose = () => {
    popupRef.current?.close();
  };

  const handleSave = () => {
    turnOffEditMode();
    addFullscreenButton();
    const geo = ref.current?.toGeoJSON() as FeatureCollection<Polygon, GeoJsonProperties>;
    if (geo?.type === 'FeatureCollection') {
      setGeojson?.(geo);
    }
    toggleFullscreen();
  };

  const handleDelete = () => {
    if (activeLayer) {
      ref.current?.removeLayer(activeLayer);
    }
    setIsDirty(true);
  };

  const handleCreate = () => {
    turnOffEditMode();
    drawRef.current = new Leaflet.Draw.Polygon(map as Leaflet.DrawMap, {
      allowIntersection: false,
      shapeOptions,
    });
    drawRef.current?.enable();
  };

  const handleChange = (isChanged: boolean) => {
    if (isChanged) {
      removeFullscreenButton();
    } else {
      addFullscreenButton();
    }
    setIsDirty(isChanged);
  };

  const handleNameUpdate = (name: string) => {
    if (!activeLayer) {
      return;
    }
    activeLayer.feature = activeLayer.feature || { type: 'Feature', properties: {} };
    activeLayer.feature.properties = activeLayer.feature.properties || {};
    activeLayer.feature.properties.name = name;
    handleChange(true);
    turnOffEditMode();
    handlePopupClose();
  };

  const activePolygonName = getPolygonName((activeLayer as GeoJSON)?.toGeoJSON() as Feature, activeLayer?._leaflet_id);

  /** List of names of existing polygons without the active polygon name */
  const existingNames =
    ref.current
      ?.getLayers()
      ?.map((layer) => String(layer.feature?.properties?.name || '').toLowerCase())
      .filter((name) => !!name && name !== (activePolygonName || '').toLowerCase()) ?? [];

  return (
    <FeatureGroup ref={ref} eventHandlers={eventHandlers} pathOptions={shapeOptions}>
      {isFullscreen ? (
        <>
          <Tooltip sticky>
            <Button color="secondary" endIcon={<EditIcon />}>
              {tooltipName}
            </Button>
          </Tooltip>
          {
            <PolygonPopup
              name={activePolygonName}
              existingNames={existingNames}
              ref={popupRef}
              onDelete={handleDelete}
              handleNameUpdate={handleNameUpdate}
            />
          }
          <PolygonEditPanel
            handleSave={handleSave}
            handleCreate={handleCreate}
            handlePopupClose={handlePopupClose}
            resetState={resetState}
            turnOffEditMode={turnOffEditMode}
            shapeOptions={shapeOptions}
            isDirty={isDirty}
            setIsDirty={handleChange}
          />
        </>
      ) : (
        <PolygonEditToggle
          enableEditMode={() => {
            toggleFullscreen();
          }}
        />
      )}
    </FeatureGroup>
  );
}
