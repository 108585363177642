import { Button } from '@mui/material';
import { useBFFPlacesDetailsQuery } from 'api/searchBff/hooks/usePlaceDetails';
import { MapPlaceholder } from 'components/general/MapPlaceholder';
import { StaticLocationMap } from 'components/leads/StaticLocationMap';
import { LeafletMap } from 'components/map/LeafletMap';
import { LeafletRectanglesAndPolygons } from 'components/map/LeafletMap/LeafletRectanglesAndPolygons';
import { getOverallCoordinates } from 'components/map/MapUtils';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { env } from 'env';
import { FeatureCollection, GeoJsonProperties, Polygon } from 'geojson';
import Leaflet from 'leaflet';
import { memo, useState } from 'react';
import { useTranslation } from 'util/i18next';
import { useFeatureToggles } from 'util/useFeatureToggles';
import { useShowError } from 'util/useShowError';

const SearchDemandMapComponent = ({
  placeIds,
  selectedPlaceId,
  gestureHandling,
  showStaticMap = false,
  geojson,
  setGeojson,
}: {
  placeIds?: string[];
  selectedPlaceId?: string;
  gestureHandling?: boolean;
  showStaticMap?: boolean;
  geojson?: FeatureCollection<Polygon, GeoJsonProperties>;
  setGeojson?: (featureCollection: FeatureCollection<Polygon, GeoJsonProperties>) => void;
}): JSX.Element | null => {
  const { t } = useTranslation(['admin', 'lead']);
  const [features] = useFeatureToggles();
  const [isMapShown, setMapShown] = useState(
    !['local', 'local-dev', 'feature', 'dev'].includes(env.VITE_LEADHUB_ENV) || features.debugMaps,
  );
  const [isInteractive, setIsInteractive] = useState(false);
  const { details, isError } = useBFFPlacesDetailsQuery(placeIds, {
    enabled: !!placeIds?.length && isMapShown,
  });
  const {
    activeShop: { latitude, longitude },
  } = useActiveShop();
  useShowError(t('lead:loadingMapError'), isError);

  const location = details?.[0]?.location || {
    lat: latitude,
    lng: longitude,
  };

  if (!isMapShown || !location) {
    return (
      <MapPlaceholder>
        <Button variant="outlined" color="secondary" onClick={() => setMapShown(true)}>
          {t('lead:showMap')}
        </Button>
      </MapPlaceholder>
    );
  }

  if (!isInteractive && showStaticMap) {
    return (
      <StaticLocationMap
        location={location}
        onClick={() => {
          setIsInteractive(true);
        }}
        details={details}
        geojson={geojson}
        showMarker={false}
      />
    );
  }

  const bound = Leaflet.latLng(latitude, longitude)?.toBounds(5000);
  const shopCenteredBounds = bound ? { northEast: bound?.getNorthEast(), southWest: bound?.getSouthWest() } : undefined;
  const overallCoordinates = details?.length ? getOverallCoordinates(details, selectedPlaceId) : shopCenteredBounds;

  return (
    <LeafletMap boundsWithoutMarkers={overallCoordinates} gestureHandling={gestureHandling}>
      <LeafletRectanglesAndPolygons
        details={details}
        overallCoordinates={overallCoordinates}
        geojson={geojson}
        setGeojson={setGeojson}
      />
    </LeafletMap>
  );
};

export const SearchDemandMap = memo(SearchDemandMapComponent);
