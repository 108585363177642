import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { PropertyDetailsFormData } from 'util/schemas/propertyDetailsSchema';

export function KnownPropertyToggle() {
  const { t } = useTranslation(['lead']);
  const { control } = useFormContext<PropertyDetailsFormData>();

  const knowPropertyArray = [
    {
      label: t('lead:propertyEvaluateForm.addPropertyId'),
      value: true,
    },
    {
      label: t('lead:propertyEvaluateForm.addSearchDemand'),
      value: false,
    },
  ] as const;

  return (
    <FormControl>
      <Controller
        name="_internals.isKnownProperty"
        control={control}
        render={({ field }) => (
          <RadioGroup row name="know-property-radio-group">
            {knowPropertyArray.map((item) => (
              <FormControlLabel
                {...field}
                key={item.label}
                control={
                  <Radio
                    checked={field.value === item.value}
                    value={item.value}
                    color="secondary"
                    onChange={(event) => {
                      field.onChange(event.target.value === 'true');
                    }}
                  />
                }
                label={item.label}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}
