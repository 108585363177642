import { MODAL_Z_INDEX } from '@ev/eva-container-api';
import { styled } from '@mui/material/styles';
import { MapContainer } from 'react-leaflet';

export const StyledMapContainer = styled(MapContainer)({
  '& .leaflet-draw-section': {
    display: 'none',
  },

  '& .leaflet-left': {
    width: ' 100%',
  },

  '& .leaflet-control-custom': {
    width: 'min(500px, 90vw)',
    float: 'none',
    padding: '20px',
    background: 'white',
    display: 'flex',
    justifContent: 'space-between',
  },

  '&.leaflet-pseudo-fullscreen.leaflet-container': {
    zIndex: MODAL_Z_INDEX - 1,
  },

  '& path.leaflet-interactive:focus': {
    outline: 'none',
  },

  '& .leaflet-control-section': {
    display: 'inline-block',
  },

  '& .leaflet-custom-button': {
    padding: '8px 16px',
    border: 0,
  },
});
