import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { GetRequestQuery, PostRequestBody, PostResponseData, fetchClient } from 'api/rest/fetch-client';
import { Go3ActivityFeatures } from 'api/rest/models/go3/Go3ActivityFeatures';

export type UpdateCallActivityRequest = PostRequestBody<'/api/v1/activities/call'>;
export type UpdateCallActivityResponse = PostResponseData<'/api/v1/activities/call'>;

export const useUpdateCallActivity = () => {
  const queryClient = useQueryClient();
  const { mutate: updateCallActivity, ...rest } = useMutation(
    ({ requestBody }: { requestBody: UpdateCallActivityRequest }) =>
      fetchClient
        .POST('/api/v1/activities/call', {
          body: requestBody,
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { updateCallActivity, ...rest };
};

export type RetrieveGo3ActivityRequest = GetRequestQuery<'/api/v1/activities/go3'>;
export type RetrieveGo3ActivityResponse = PostResponseData<'/api/v1/activities/go3'>;

export const useRetrieveGo3Activities = (requestBody: RetrieveGo3ActivityRequest, disableQuery?: boolean) =>
  useQuery({
    queryKey: ['retrieveGo3Activities', requestBody],
    enabled: !!requestBody.go3ContactUtag && !disableQuery,
    queryFn: async () => {
      const response = await fetchClient.GET('/api/v1/activities/go3', {
        params: {
          query: requestBody,
        },
      });
      return response.data as Go3ActivityFeatures[];
    },
  });
