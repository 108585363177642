import { Divider, Stack, SxProps, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { IntentTypeEnum } from 'api/graphql/generated/graphql';
import { useLastInteractionActivities } from 'api/graphql/hooks/useActivityDetails';
import { DuplicateContactInfo } from 'api/rest/models/ContactDuplicates';
import { AgentSet } from 'components/contact/AgentSet/AgentSet';
import { Blocked } from 'components/contact/Blocked';
import { DateLabel } from 'components/general/DateLabel/DateLabel';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { FieldListItem } from 'components/general/FieldList/FieldList';
import { Section } from 'components/general/Section/Section';
import { SectionContent } from 'components/general/Section/SectionContent';
import { SectionHeader } from 'components/general/Section/SectionHeader';
import { LastInteractionItem } from 'page-components/LeadListPage/LeadRow/LastInteractionItem/LastInteractionItem';
import { RowWrapper, StyledChip } from 'page-components/contact/LayoutContact/LinkContacts/styles';
import { ReactNode } from 'react';
import { renderEmptyableString } from 'util/emptyDataUtils';
import { DEFAULT_LAST_INTERACTION_FILTER_CATEGORIES } from 'util/hasura/filters';
import { useTranslation } from 'util/i18next';
import { fullName } from 'util/stringUtils';

export const DuplicateContactCard = ({
  duplicateContact,
  showLastInteraction,
}: {
  duplicateContact: DuplicateContactInfo;
  showLastInteraction?: boolean;
}) => {
  const { t } = useTranslation(['contact']);

  const { lastInteractions } = useLastInteractionActivities(
    {
      where: {
        category: { _in: DEFAULT_LAST_INTERACTION_FILTER_CATEGORIES },
        contactId: { _eq: duplicateContact.matchingContactId },
      },
      limit: 1,
      orderBy: { orderDate: 'DESC' },
    },
    { enabled: !!duplicateContact.matchingContactId && showLastInteraction },
  );

  const getAgents = (type: IntentTypeEnum) =>
    duplicateContact.agents?.filter((data) => data.leadIntent === type && data.agentId);

  const buyerAgents = getAgents('BUY');
  const sellerAgents = getAgents('SELL');
  const toRentAgents = getAgents('TO_RENT');
  const rentOutAgents = getAgents('RENT_OUT');

  const hasAgents = Boolean(
    buyerAgents?.length || sellerAgents?.length || toRentAgents?.length || rentOutAgents?.length,
  );
  const showDivider = Boolean(duplicateContact.go3Utag || duplicateContact.go3TimeOfLastActivity || hasAgents);

  const duplicateContactFullName = fullName(duplicateContact.name);

  return (
    <Paper
      elevation={2}
      sx={{
        margin: '25px',
      }}
    >
      <Section collapsible={false} noSpacing sx={{ pt: 1.5 }}>
        <SectionHeader sxContainer={{ display: 'block' }}>
          <Stack direction={'row'} justifyContent={'space-between'} paddingLeft={2} paddingRight={2}>
            <StyledChip
              avatar={<EVAvatar person={duplicateContact.name} size="m" />}
              label={duplicateContactFullName}
            />
            {duplicateContact.blocked && <Blocked label={t('contact:duplicateContact.card.blocked')} />}
          </Stack>
        </SectionHeader>
        <SectionContent sx={{ pb: 1 }}>
          <RowItem
            label={t('contact:duplicateContact.card.label.fullName')}
            text={renderEmptyableString(duplicateContactFullName)}
            markDuplicate={duplicateContact.name?.matching}
          />
          {duplicateContact.emailAddresses?.map((data) => (
            <RowItem
              key={data.value}
              label={t('contact:duplicateContact.card.label.email')}
              text={renderEmptyableString(data.value)}
              markDuplicate={data.matching}
            />
          ))}
          {duplicateContact.phoneNumbers?.map((data) => (
            <RowItem
              key={data.value}
              label={t('contact:duplicateContact.card.label.phone')}
              text={renderEmptyableString(data.value)}
              markDuplicate={data.matching}
            />
          ))}
          {showDivider && <Divider sx={{ mt: 2, mb: 1 }} />}
          {!!duplicateContact.go3Utag && (
            <RowItem label={t('contact:duplicateContact.card.label.evId')}>
              <Typography variant="body3">{duplicateContact.go3Utag}</Typography>
            </RowItem>
          )}
          {!!duplicateContact.go3TimeOfLastActivity && (
            <RowItem label={t('contact:duplicateContact.card.label.lastActivity')}>
              <DateLabel type="fullDate" dateString={duplicateContact.go3TimeOfLastActivity} />
            </RowItem>
          )}
          {showLastInteraction && !!lastInteractions?.length && (
            <RowItem label={t('contact:duplicateContact.card.label.lastInteraction')} sx={{ alignItems: 'flex-start' }}>
              <LastInteractionItem lastInteraction={lastInteractions[0]} showAgentName />
            </RowItem>
          )}
          {!!buyerAgents?.length && (
            <RowItem label={t('contact:duplicateContact.card.label.agents.buyer')}>
              <AgentSet agents={buyerAgents} />
            </RowItem>
          )}
          {!!sellerAgents?.length && (
            <RowItem label={t('contact:duplicateContact.card.label.agents.seller')}>
              <AgentSet agents={sellerAgents} />
            </RowItem>
          )}
          {!!toRentAgents?.length && (
            <RowItem label={t('contact:duplicateContact.card.label.agents.toRent')}>
              <AgentSet agents={toRentAgents} />
            </RowItem>
          )}
          {!!rentOutAgents?.length && (
            <RowItem label={t('contact:duplicateContact.card.label.agents.rentOut')}>
              <AgentSet agents={rentOutAgents} />
            </RowItem>
          )}
        </SectionContent>
      </Section>
    </Paper>
  );
};

export const RowItem = ({
  label,
  text,
  markDuplicate,
  children,
  sx,
  sxLabel,
}: {
  label: string;
  text?: string;
  markDuplicate?: boolean;
  children?: ReactNode;
  sx?: SxProps;
  sxLabel?: SxProps;
}) => (
  <RowWrapper sx={sx} markDuplicate={markDuplicate}>
    <FieldListItem sxLabel={sxLabel} label={label} text={text}>
      {children}
    </FieldListItem>
  </RowWrapper>
);
